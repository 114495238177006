import 'sweetalert2/dist/sweetalert2.min.css';
import 'public/style.scss'
import 'public/main'
import Cookies from 'js-cookie';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import lottie from "lottie-web/build/player/lottie_light.min.js";
import Rails from "@rails/ujs"
import { tsParticles } from "tsparticles-engine";
// import { loadFull } from "tsparticles";
import { loadColorUpdater } from "tsparticles-updater-color";
import { loadCircleShape } from "tsparticles-shape-circle";
import { loadBaseMover } from "tsparticles-move-base";
import { loadSizeUpdater } from "tsparticles-updater-size";
import { loadOpacityUpdater } from "tsparticles-updater-opacity";
import { loadOutModesUpdater } from "tsparticles-updater-out-modes";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';

// loadFull(tsParticles); // this is needed to load all the features
gsap.registerPlugin(ScrollTrigger);
Rails.start();
const Swal_lg = Swal.mixin({
  width: 660,
  showCloseButton: true,
  showConfirmButton: false,
});

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.lottie = lottie;
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.Swal = Swal;
window.Swal_lg = Swal_lg;

$(function() {
  if ($('.show-opacity').length) {
    gsap.set(".show-opacity", { opacity: 0 });
    ScrollTrigger.batch(".show-opacity", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { opacity: 1, stagger: 0.3 });
      },
    });
  }
  if ($('.show-in-top').length) {
    gsap.set(".show-in-top", { opacity: 0, y: -30 });
    ScrollTrigger.batch(".show-in-top", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { ease: 'back.out(1.5)', opacity: 1, y: 0, stagger: 0.6 });
      },
    });
  }
  if ($('.show-to-top').length) {
    gsap.set(".show-to-top", { y: 50, opacity: 0 });
    ScrollTrigger.batch(".show-to-top", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { opacity: 1, y: 0, stagger: 0.1 });
      },
    });
  }
  if ($('.show-in-top').length) {
    gsap.set(".show-in-top", { opacity: 0, y: -30 });
    ScrollTrigger.batch(".show-in-top", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { ease: 'back.out(1.5)', opacity: 1, y: 0, stagger: 0.6 });
      },
    });
  }
  if ($('.show-in-left').length) {
    gsap.set(".show-in-left", { opacity: 0, x: -30 });
    ScrollTrigger.batch(".show-in-left", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { ease: 'back.out(1.5)', opacity: 1, x: 0,  stagger: 0.3 });
      },
    });
  }

  const lightbox = new PhotoSwipeLightbox({
    gallery: '#showcases_gallery',
    children: 'a',
    pswpModule: () => import('photoswipe'),
    padding: { top: 20, bottom: 60, left: 10, right: 10 },
    maxWidthToAnimate: 1500,
  });
  // const lightbox = new PhotoSwipeLightbox(options);
  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }
          el.innerHTML = captionHTML || '';
        });
      }
    });
  });
  lightbox.init();
});

$(window).on("load", function () {
  if ($('#starBg').length) {
    var star_area;
    var star_max_size;
    let windowW = $(window).outerWidth();
    if (windowW > 768) {
      star_area = 1000;
      star_max_size = 6;
    } else {
      star_area = 500;
      star_max_size = 4;
    }
    (async () => {
      await loadColorUpdater(tsParticles);
      await loadCircleShape(tsParticles);
      await loadBaseMover(tsParticles);
      await loadSizeUpdater(tsParticles);
      await loadOpacityUpdater(tsParticles);
      await loadOutModesUpdater(tsParticles);

      await tsParticles.load("starBg", {
        fpsLimit: 160,
        particles: {
          color: { value: ["#d35027", "#d35027", "#9a7e4c", "#ffe02d"] },
          move: {
            direction: "none",
            enable: true,
            outModes: "out",
            random: true,
            speed: 1.6,
            straight: false
          },
          number: { density: { enable: true, area: star_area }, value: 13 },
          opacity: {
            value: { min: 0, max: 1 }
          },
          shape: {
            type: "circle"
          },
          size: {
            value: { min: 1, max: 6 }
          }
        }
      });
    })();
  }
});

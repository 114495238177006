$(document).ready( function () {
  // header
  $('.header-menu-link.dropdown').find('.menu-link').on('click', function(e) {
    let windowW = $(window).outerWidth();
    if(windowW <= 860) {
    e.preventDefault();
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).siblings('.header-menu-dropdown').removeClass('active');
        console.log(1)
      }else {
        $(this).addClass('active');
        $(this).siblings('.header-menu-dropdown').addClass('active');
        console.log(2)
      }
    }
  });
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu').toggleClass('active');
  });

  $('.header-menu .dropdown-link').on('click', function () {
    $('.header-mobile-menu').removeClass('active');
    $('.header-menu').removeClass('active');
  });

  //scroll top
  $('#backToTop').on('click', function(){
    $("html").animate({scrollTop: 0}, 1000);
  });


  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 200) {
      $("#backToTop").fadeIn();
    } else {
      $("#backToTop").fadeOut();
    }
  });

});
